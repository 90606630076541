import React from "react"
import Header from "../components/header-new"
import Footer from "../components/footer-cannabis"
import { Helmet } from "react-helmet"

import "../assets/index.css"

function getTitle(props) {
    return props.title;
}

const Layout = ({ children, location, ...props }) => (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{getTitle(props)}</title>
          <link rel="canonical" href="https://laddpartners.com" />

          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-145886969-1"></script>
            <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'UA-145886969-1');
            `}
            </script>
        </Helmet>
        <main id="contact-us">
            <Header/>
            {children}
            <Footer/>
        </main>
    </>
)

export default Layout
