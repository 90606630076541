import React from "react"
import styled from "styled-components"
import { Location } from '@reach/router'

const WrapperDiv = styled.div`
    padding: 15px;
    margin-bottom: 10px;
    background: #33bba16e;
    color: #259882;
    font-weight: 500;
`;


const barDiv = () => {
    return (
        <WrapperDiv> Thank you for message. We will reach back out to you within 24 hours. </WrapperDiv>
    )
}

const Bar = () => (
    <Location>
      {({ location }) => {
        return  <>{location.search ? barDiv() : <></>}</>
      }}
    </Location>
)

export default Bar

