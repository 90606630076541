import React from "react"
import "../../assets/index.css"
import LayoutContactUs from "../../layouts/layout-contactus"
import Hero from "../../components/hero-contactus";
import ContactUsForm from "../../components/form-contactus";

export default () => (
    <LayoutContactUs  title="Contact Us | Ladd Partners">
        <Hero></Hero>
        <ContactUsForm></ContactUsForm>
    </LayoutContactUs>
)
