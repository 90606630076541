import React from "react"
import styled from "styled-components"
import Bar from "../components/bar"
import Recaptcha from 'react-recaptcha';

/* eslint-disable jsx-a11y/accessible-emoji */
const ContactUsWrapperDiv = styled.div`
    background-color: #E5F5FB;
    margin-bottom: 160px;
    padding-bottom: 100px;

    #g-recaptcha > div {
        float: right;
    }
`;

const ContactWrapperDiv = styled.div `
    display: flex;
    flex-wrap: wrap;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;

    @media only screen and (max-width: 1140px) {
        width: 100%;
    }
`;

const ContactUs = styled.div`
    width: 50%;
    padding: 100px 50px;
    box-sizing: border-box;
    text-align: center;

    > div {
        width: 100%;
        max-width: 350px;
        margin: auto;
    }

    hr {
        margin: 5px auto 20px;
        background: var(--blue);
    }

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 40px 0px;
    }
`;

const ContactUsForm = styled.div`
    padding: 60px 50px 0;
    width: 50%;
    box-sizing: border-box;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0 5px 0;
    }

    p {
        margin-bottom: 20px;
    }

   form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        @media only screen and (max-width: 1140px) {
            margin-bottom: 0;

            .g-recaptcha > div {
                width: 100% !important;
            }
        }

        > div {
            width: 100%;
            position: relative;
        }
    }

    input, textarea {
        width: 100%;
        padding: 15px;
        border-radius: 3px;
        border: #919EAB 1px solid;
        margin-bottom: 30px;
        background: #F9FCFF;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        ::placeholder {
            color: #919EAB;
            font-weight: 500;
          }

        :focus, :hover {
            outline: none;
            border-color: #919AA2;
        }
    }

    select {
        width: 100%;
        padding: 15px;
        border-radius: 3px;
        border: #919EAB 1px solid;
        margin-bottom: 30px;
        background: #F9FCFF;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        background: white url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='18' height='18' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='grey'/></g></svg>") no-repeat;
        background-position: right 15px top 50%;

        &:required:invalid {
            color: #919EAB;
            font-weight: 500;
          }

        :focus, :hover {
            outline: none;
            border-color: #919AA2;
        }

        option:not(:first-of-type) {
            color: red!important;
          }
    }

    .has-error input,
    .has-error select,
    .has-error textarea {
        border-color: #F70545;
    }

    has-error.textarea {
        margin-bottom: 35px;
    }

    .has-error p {
        color: #F70545;
        font-size: 14px;
        position: absolute;
        margin-bottom: 0;
        bottom: 5px;
    }

    .has-error textarea + p {
        @media only screen and (max-width: 400px) {
            bottom: -15px;
        }
    }

    button {
        @media only screen and (min-width: 1140px) {
            max-width: 260px;
        }
    }
`;

const contactSVG = () => (
    <svg width="95px" height="95px" viewBox="0 0 95 95" version="1.1">
        <title>Contact Icon</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="GET-IN-TOUCH" transform="translate(-165.000000, -105.000000)">
                <rect id="Rectangle-Copy" fill-opacity="0.1" fill="none" x="0" y="0" width="1440" height="300"></rect>
                <g id="Group" transform="translate(165.000000, 105.000000)" fill-rule="nonzero">
                    <g id="Contact-US---Icon">
                        <path d="M78.3534091,78.1590909 L78.3534091,72.9017045 C78.3534091,71.9358338 77.5704162,71.1528409 76.6045455,71.1528409 C75.6386747,71.1528409 74.8556818,71.9358338 74.8556818,72.9017045 L74.9744318,82.3693182 C74.9727318,82.8329194 75.1598143,83.2772406 75.4926136,83.6 C75.8105479,83.9255267 76.2466787,84.1085459 76.7017045,84.1073918 L76.7017045,84.1073918 L86.1693182,83.9886364 C87.130209,83.9651931 87.8968768,83.1793586 87.8965909,82.2181818 C87.8847699,81.2607534 87.1052287,80.4908361 86.1477273,80.4909091 L86.1477273,80.4909091 L80.8903409,80.4909091 L81.1386364,80.2210227 C93.1126338,67.9486949 97.4521565,50.1289139 92.4614083,33.7252839 C87.47066,17.3216539 73.9419162,4.93823188 57.1619318,1.41420455 C48.6284393,-0.348351075 39.7737563,0.283058811 31.5767045,3.23863636 C30.8106569,3.64290953 30.4591981,4.54952278 30.7525897,5.36449952 C31.0459813,6.17947626 31.8946635,6.65403876 32.7426136,6.47727273 C51.8378467,-0.422106887 73.1661819,6.80743781 84.1303935,23.8958968 C95.0946051,40.9843558 92.7775372,63.3851457 78.5477273,77.8676136 L78.3534091,78.1590909 Z" id="Path" fill="#33BBA1"></path>
                        <path d="M63.4232955,91.7613636 C64.0882976,91.6227355 64.6115929,91.1093935 64.7629644,90.4471758 C64.9143359,89.7849581 64.6660387,89.0952434 64.1273076,88.6814626 C63.5885766,88.2676818 62.8581567,88.2056774 62.2573864,88.5227273 C43.1460831,95.4658938 21.7744424,88.2452378 10.7902153,71.1339483 C-0.194011855,54.0226588 2.13921511,31.5851689 16.4090909,17.1 L16.6681818,16.8301136 L16.6681818,22.0982955 C16.6800028,23.0557239 17.4595441,23.8256412 18.4170455,23.8255682 L18.4170455,23.8255682 C19.3779363,23.8021249 20.1446041,23.0162904 20.1443182,22.0551136 L20.0255682,12.5875 C20.0149663,11.6587545 19.2802139,10.9003004 18.3522727,10.8602273 L18.3522727,10.8602273 L8.88465909,10.9681818 C7.92376823,10.9916251 7.15710043,11.7774596 7.15738636,12.7386364 C7.16920737,13.6960648 7.94874862,14.4659821 8.90625,14.4659091 L8.90625,14.4659091 L14.1636364,14.4659091 L13.9153409,14.7357955 C0.754762897,28.2643063 -3.03741958,48.35276 4.28554428,65.7480358 C11.6085081,83.1433116 28.6262197,94.4713349 47.5,94.5142045 C52.9251283,94.5022841 58.309046,93.5715051 63.4232955,91.7613636 Z" id="Path" fill="#33BBA1"></path>
                        <path d="M24.2357955,67.5795455 C23.1941993,68.0905634 22.6048946,69.2185859 22.7803825,70.3654363 C22.9558704,71.5122867 23.855526,72.4124726 25.0022727,72.5886364 C25.6057662,72.6823879 26.2160189,72.7257195 26.8267045,72.7181818 C30.0465651,72.6667222 33.1806368,71.6720767 35.8409091,69.8573864 C47.3540512,75.9052262 61.5762669,72.2588199 68.7592354,61.4175206 C75.9422039,50.5762212 73.7552092,36.0577933 63.697281,27.8135899 C53.6393529,19.5693865 38.974069,20.2744713 29.7534418,29.4455587 C20.5328147,38.616646 19.7487361,53.2779187 27.9386364,63.3801136 C27.186558,65.1407429 25.8884221,66.6129729 24.2357955,67.5795455 Z M32.2028409,31.9545455 C40.234679,23.9370845 53.0658484,23.398829 61.7409368,30.71545 C70.4160253,38.032071 72.0498343,50.7701751 65.5020599,60.0393041 C58.9542855,69.3084331 46.4026149,72.0258201 36.6073864,66.2948864 C36.3383448,66.1379101 36.0335926,66.0524308 35.7221591,66.0465909 C35.3307197,66.0405908 34.9492728,66.1702827 34.6426136,66.4136364 C32.7191268,67.9268563 30.413175,68.876146 27.9818182,69.1556818 C29.5420565,67.6256926 30.766372,65.787381 31.5767045,63.7579545 C31.8362778,63.1509283 31.731893,62.4494623 31.3068182,61.9443182 C23.6578818,53.2850209 24.0500432,40.1736006 32.2028409,31.9869318 L32.2028409,31.9545455 Z" id="Shape" fill="#3D7BB2"></path>
                        <path d="M47.5,49.2488636 C48.469425,49.2489357 49.2586323,48.4693529 49.2704545,47.5 C49.2673027,47.0373825 49.0809777,46.5948607 48.7522727,46.2693182 C48.4187735,45.9393247 47.9691619,45.7532784 47.5,45.7511364 C46.5474376,45.774309 45.7840655,46.5472232 45.7727273,47.5 C45.7804589,47.9616645 45.9660862,48.4025294 46.2909091,48.7306818 C46.6111452,49.0541777 47.0448919,49.2400691 47.5,49.2488636 Z" id="Path" fill="#33BBA1"></path>
                        <path d="M53.9772727,49.2488636 C54.9419705,49.2375185 55.7245466,48.464486 55.7477273,47.5 C55.7453572,47.0350609 55.554477,46.5909723 55.21875,46.2693182 C54.897444,45.9375385 54.4553266,45.7502405 53.9934659,45.7502405 C53.5316053,45.7502405 53.0894878,45.9375385 52.7681818,46.2693182 C52.4353825,46.5920776 52.2482999,47.0363987 52.2499885,47.5 C52.2519653,47.9628605 52.4384738,48.4058182 52.7681818,48.7306818 C53.0909527,49.0504345 53.5231271,49.2356521 53.9772727,49.2488636 Z" id="Path" fill="#33BBA1"></path>
                        <path d="M41.0227273,49.2488636 C41.4853448,49.2457118 41.9278666,49.0593868 42.2534091,48.7306818 C42.586159,48.4060013 42.7797064,47.9647132 42.7931818,47.5 C42.7931818,46.5341293 42.0101889,45.7511364 41.0443182,45.7511364 C40.0784475,45.7511364 39.2954545,46.5341293 39.2954545,47.5 C39.3031862,47.9616645 39.4888135,48.4025294 39.8136364,48.7306818 C40.1325435,49.0561246 40.5671258,49.2423742 41.0227273,49.2488636 Z" id="Path" fill="#33BBA1"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

class ContactUsPage extends React.Component {
    state = {
        email: '',
        first_name: '',
        last_name: '',
        description: '',
        interested: '', 
        formErrors: {
            email: '',
            first_name: '',
            last_name: '',
            description: '',
            interested: ''
        },
        emailValid: false,
        interestedValid: false,
        firstNameValid: false,
        lastNameValid: false,
        descriptionValid: false,
        formValid: false
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({[name]: value},
            () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let interestedValid = this.state.interestedValid;
        let emailValid = this.state.emailValid;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        let descriptionValid = this.state.descriptionValid;

        switch(fieldName) {
          case 'interested':
            interestedValid = value.length > 0;;
            fieldValidationErrors.interested = interestedValid ? '': ' is required.';
            break;  
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' is invalid.';
            break;
          case 'first_name':
            firstNameValid = value.length > 0;
            fieldValidationErrors.first_name = firstNameValid ? '': ' is required.';
            break;
          case 'last_name':
                lastNameValid = value.length > 0;
                fieldValidationErrors.last_name = lastNameValid ? '': ' is required.';
            break;
          case 'description':
                descriptionValid = value.length > 12;
                fieldValidationErrors.description = descriptionValid ? '': ' must be longer than 12 characters.';
            break;

            default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        interestedValid: interestedValid,
                        emailValid: emailValid,
                        firstNameValid: firstNameValid,
                        lastNameValid: lastNameValid,
                        descriptionValid: descriptionValid
                      }, this.validateForm);
      }

      handleSubmit = event => {
        document.querySelectorAll('.validate').forEach((input) => {
            const value = input.value
            const name = input.name
            this.setState({[name]: value},
                () => { this.validateField(name, value) });
        })

        if (!this.state.formValid) event.preventDefault();

        window.gtag("event", "custom_form_submit", { "event_category": "contact_us", "event_description": this.descriptionValid });

      }

      validateForm() {
        this.setState({formValid: this.state.interestedValid && this.state.emailValid && this.state.firstNameValid && this.state.lastNameValid &&  this.state.descriptionValid});
      }

      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
     }

     errorMessage(name, error) {
        return(error.length === 0 ? '' : <p key={name}>{name} {error}</p>);
     }

     callback() {
         document.querySelector('#submit').removeAttribute('disabled');
     }

      render() {
        return (
            <ContactUsWrapperDiv>
            <ContactWrapperDiv>
                <ContactUs>
                {contactSVG()}
                    <div>
                        <h1 className="margin-bottom-10 margin-top-10">Get in touch!</h1>
                        <hr/>
                        <p>Our team is happy to answer any questions. Send us a message or call us dirtectly at <a href="tel:408-461-7997">408-461-7997</a>. We’re looking forward to connecting with you.</p>
                    </div>
                </ContactUs>
                <ContactUsForm>
                    <Bar/>
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" noValidate onSubmit={this.handleSubmit}> 
                        <input type="hidden" name="oid" value="00D36000000pbA2"/>
                        <input type="hidden" name="retURL" value="https://www.laddpartners.com/company/contact-us?submit=success"/>
                        <input type="hidden" name="lead_source" value="Website"/>


                        <div className={`form-group ${this.errorClass(this.state.formErrors.interested)}`}>
                            <div className="margin-bottom-10"> I'm interested in...</div>
                            <select id="interested" className="validate" name="interested" required
                                            onChange={this.handleInputChange}
                                            >
                                <option disabled selected hidden value="">Select an option...</option>
                                <option>Managed Support Services</option>
                                <option>Implementation Services</option>
                                <option>Cannabis Essentials</option>
                                <option>Other</option>

                            </select>
                            {this.errorMessage('Interest', this.state.formErrors.interested)}
                        </div>

                        <div className={`form-group ${this.errorClass(this.state.formErrors.first_name)}`}>
                            <input id="first_name" className="validate" maxLength="40" name="first_name" size="20" placeholder="Your First Name" required
                                            onChange={this.handleInputChange}
                                            value={this.state.first_name}
                                            ></input>
                            {this.errorMessage('First Name', this.state.formErrors.first_name)}
                        </div>
                        <div className={`form-group ${this.errorClass(this.state.formErrors.last_name)}`}>
                            <input id="last_name" className="validate" maxLength="80" name="last_name" size="20" placeholder="Your Last Name" required
                                            onChange={this.handleInputChange}
                                            value={this.state.last_name}
                                            ></input>
                            {this.errorMessage('Last Name', this.state.formErrors.last_name)}
                        </div>
                        <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
                            <input id="email" className="validate" maxLength="80" name="email" size="20"  placeholder="Your Email" required
                                              onChange={this.handleInputChange}
                                              value={this.state.email}
                                              ></input>
                            {this.errorMessage('Email', this.state.formErrors.email)}
                        </div>

                        <div>
                            <input id="company" maxLength="40" name="company" size="20" placeholder="Your Company"
                                            onBlur={this.handleInputChange}></input></div>

                        <div className={`form-group textarea ${this.errorClass(this.state.formErrors.description)}`}>
                            <textarea className="validate" placeholder="Message" name="description" rows="7" required
                            onChange={this.handleInputChange}
                            value={this.state.description}
                            ></textarea>
                            {this.errorMessage('Message', this.state.formErrors.description)}

                        </div>
                        <div>
                        <Recaptcha
                            sitekey="6LcnBfEUAAAAAA0ZZbvkTUH4USvHiNvF9DHHK77f"
                            render="explicit"
                            verifyCallback={this.callback}
                        />
                        </div>
                        <div><button id="submit" type="submit" disabled>Submit</button></div>
                    </form>
                </ContactUsForm>
            </ContactWrapperDiv>
        </ContactUsWrapperDiv>
        )
      }
}

export default ContactUsPage